
export default class Pattern {
  constructor(element) {
    this.element = element;
    this.patternBoxes = document.querySelectorAll(".c-pattern--flexible");
    this.patternContent = this.element.querySelector(".c-pattern__content");

    this.patternWidth = 135;
    this.patternHeight = 100;
    this.patternRow;
    this.patternColumnNumber;
    this.patternBoxWidth;
    this.patternBoxHeight;
    this.patternWidthPlusNegativeMargin = this.patternWidth - 5;

    this.scrollbarWidth = window.innerWidth - document.body.clientWidth;

    this.handleResize = this.handleResize.bind(this);
  }

  mount() {
    document.body.style.setProperty("--scrollbarWidth", `${this.scrollbarWidth}px`);

    window.addEventListener('resize', this.handleResize);

    this.calcPattern();
  }

  handleResize() {
    document.body.style.setProperty("--scrollbarWidth", `${this.scrollbarWidth}px`);
    this.calcPattern();
  }

  calcPattern() {
    this.patternContent = this.element.querySelector(".c-pattern__content");
    this.patternBoxWidth = Math.floor(this.element.offsetWidth);
    this.patternBoxHeight = Math.floor(this.element.offsetHeight);

       this.patternContent.innerHTML="";

      // Columns
      if( this.element.getAttribute("data-pattern-overflow") == "x" ||  this.element.getAttribute("data-pattern-overflow") == "xy") {
        // Overflow
         this.element.classList.add("pattern--overflow");

        this.patternColumnNumber = Math.ceil(((this.patternBoxWidth + this.patternWidth) -(this.patternBoxWidth % this.patternWidthPlusNegativeMargin)) / this.patternWidth) ;
        this.patternContent.style.width= (this.patternWidth *this.patternColumnNumber) + "px" ;
        this.patternContent.style.height= (this.patternHeight * (this.element.getAttribute("data-pattern-row"))) + "px" ;
      }else {
        // No Overflow
        this.patternColumnNumber = (this.patternBoxWidth -(this.patternBoxWidth % this.patternWidthPlusNegativeMargin)) /this.patternWidth ;
      }

      // Rows
      if(this.element.getAttribute("data-pattern-overflow") == "y" || this.element.getAttribute("data-pattern-overflow") == "xy") {
        // Overflow
        if(this.element.hasAttribute("data-pattern-row")){
          this.patternRow = this.element.getAttribute("data-pattern-row");
          this.element.style.overflow="visible";
        }else {
          this.patternRow = Math.ceil(this.patternBoxHeight/ this.patternHeight);
        }

      }else {
        // No Overflow
        if(this.element.hasAttribute("data-pattern-row")){
          this.patternRow = this.element.getAttribute("data-pattern-row");
        }else {
          this.patternRow = Math.floor(this.patternBoxHeight/ this.patternHeight);
        }
      }

      let patterTotal;

      if (window.matchMedia("(min-width: 620px)").matches) {
        patterTotal = this.patternColumnNumber * this.patternRow;
      } else {
        patterTotal = 9;
      }

      let patternDivs;

      for (let i = 0; i < patterTotal; i++) {
        var board = document.createElement('div');
          board.className = "c-pattern__element";
          this.patternContent.appendChild(board);
      }
  }


}
