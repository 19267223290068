import 'mdn-polyfills/NodeList.prototype.forEach';

document.addEventListener('DOMContentLoaded', () => {
  const isReduced =
  window.matchMedia(`(prefers-reduced-motion: reduce)`) === true ||
  window.matchMedia(`(prefers-reduced-motion: reduce)`).matches === true;

    if (!!isReduced) {

    } else {
      const heading = document.querySelector('.page-template-landing .c-heading');

      if (!heading) {
        return;
      }else {
        let buttonContact = document.querySelector('.c-button--contact');
        let isHidden = false;
        let observer = new IntersectionObserver((entries, observer) => {
          entries.forEach(entry => {
            if(entry.intersectionRatio!=1){
              isHidden = true;
              buttonContact.classList.add("is-viewport");
            }
            else if(isHidden) {
              buttonContact.classList.remove("is-viewport");
              isHidden=false
            }
          });
        }, {threshold: 1});


        observer.observe(heading);
      }
    }
});
