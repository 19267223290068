import 'mdn-polyfills/NodeList.prototype.forEach';
import { CountUp } from 'countup.js';


export default class Viewport {
  constructor(element) {
    this.element = element;
  }

  mount() {
    this.element.classList.add("is-viewport");

    if(this.element.querySelector(".c-fact")) {
      const list = this.element.querySelectorAll(".c-fact");

      for (let i = 0; i < list.length; i++) {
        const element = list[i];
        coutUp(element,i);
      }

      function coutUp(element,i) {
        setTimeout(function() {
            const counterElement = element.querySelector('.c-fact__headline');
            const counterValue = counterElement.textContent;

            if(counterValue.match(/^-?\d+$/)){
              const options = {
                useGrouping: false,
              };

              let counter = new CountUp(counterElement, counterValue, options);
              if (!counter.error) {
                counter.start();
              } else {
              }
            }
        }, (i * 300));
      }
    }

    if(this.element.querySelector(".c-info--fact")) {
      const list = this.element.querySelectorAll(".c-info--fact");

      for (let i = 0; i < list.length; i++) {
        const element = list[i];
        coutUp(element,i);
      }

      function coutUp(element,i) {
        setTimeout(function() {
            const counterElement = element.querySelector('.c-info__headline');
            const counterValue = counterElement.textContent;

            if(counterValue.match(/^-?\d+$/)){
              const options = {
                useGrouping: false,
              };

              let counter = new CountUp(counterElement, counterValue, options);
              if (!counter.error) {
                counter.start();
              } else {
              }
            }
        }, (i * 300));
      }
    }


  }

  unmount() {
    if(this.element.classList.contains("is-viewport")){
      this.element.classList.remove("is-viewport");

      const event = new Event('viewport:notIntersecting');
      document.dispatchEvent(event);
    }
  }
}
