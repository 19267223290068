import 'mdn-polyfills/NodeList.prototype.forEach';
import Viewtransition from './Viewtransition';

document.addEventListener('DOMContentLoaded', () => {
  const viewTransitionTwigger = document.querySelectorAll('.c-button--option');
  if (!viewTransitionTwigger.length) {
    return;
  }

  viewTransitionTwigger.forEach((element) => {
    const viewtransition = new Viewtransition(element);
    viewtransition.mount(element);
  });
});
