import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

export default class Bandeau {
  constructor(element) {
    this.element = element;
    this.bandeauContainers = document.querySelectorAll(".c-bandeau");
    this.bandeauWord = document.querySelectorAll(".c-bandeau__word");

    this.handleResize = this.handleResize.bind(this);
  }

  mount() {
    this.calcBandeau();

    const isReduced =
  window.matchMedia(`(prefers-reduced-motion: reduce)`) === true ||
  window.matchMedia(`(prefers-reduced-motion: reduce)`).matches === true;

    if (!!isReduced) {
    } else {
      gsap.registerPlugin(ScrollTrigger)
      this.addAnimation()
    }
  }

  calcBandeau() {
    let bandeauWording = this.bandeauWord[0].innerHTML;
    let bandeauInnerWidth = this.bandeauWord[0].offsetWidth;
    let viewportWidth = window.innerWidth;
    let numberOfWords = Math.ceil(viewportWidth / bandeauInnerWidth) + 1;
    this.bandeauContainers.forEach((bandeauContainer) => {
      for (let i = 0; i < numberOfWords; i++) {
        var board = document.createElement("div");
        board.className = "c-bandeau__word";
        board.innerHTML = bandeauWording;
        bandeauContainer.appendChild(board);
      }
    });
  }

  addAnimation(){
    var bandeaus = gsap.utils.toArray(".c-bandeau");
    bandeaus.forEach((bandeau, i) => {
      let isEven = i % 2 == 0;

      gsap.set(bandeau, {
        x: isEven ? -150 : 0
      });

      gsap.from(bandeau, {
        scrollTrigger: {
          trigger: ".blocks__item--bandeau",
          start: "top bottom",
          end: "bottom top",
          scrub: 1
        },
        x: isEven ? 0 : -150,
        ease: "none",
        duration: 1
      });
    });
  }

  handleResize() {
    this.calcBandeau();
  }
}
