import Bandeau from './Bandeau';

document.addEventListener('DOMContentLoaded', () => {
  const bandeauElement = document.querySelectorAll('.c-bandeau');
  if (!bandeauElement.length) {
    return;
  }

  bandeauElement.forEach((element) => {
    const bandeau = new Bandeau(element);
    bandeau.mount(element);
  })
});
