import 'mdn-polyfills/NodeList.prototype.forEach';

export default class Viewtransition {
  constructor(element) {
    this.element = element;

    this.contentBox = document.querySelector(".o-grid__item--content")

    this.handleClick = this.handleClick.bind(this);


  }

  handleClick(e) {
    e.preventDefault();
    // cest devenu un fake viewtransition
    let target = e.target;
    this.contentBox.classList.add("is-moveout");

    setTimeout(function() {
      window.location = target;
    }, 100, this.href);
  }


  mount() {
    this.element.addEventListener('click', this.handleClick);
  }

  navigateTo(path) {
  }

  unmount() {
  }
}
