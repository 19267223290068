import Pattern from './Pattern';

document.addEventListener('DOMContentLoaded', () => {
  const patternElement = document.querySelectorAll('.c-pattern--flexible');
  if (!patternElement.length) {
    return;
  }

  patternElement.forEach((element) => {
    const pattern = new Pattern(element);
    pattern.mount(element);
  })
});
