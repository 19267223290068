import iFrameResize from 'iframe-resizer/js/iframeResizer';

export default class Iframe {
  constructor(element) {
    this.element = element;
    this.resizer = null;

    this.handleIframeLoaded = this.handleIframeLoaded.bind(this);
  }

  handleIframeLoaded() {
    this.resizer = iFrameResize({
      scrolling: true,
      onResized: (resize) => {
        resize.iframe.setAttribute('scrolling', 'no');
      },
    }, this.element);
  }

  mount() {
    this.element.addEventListener('load', this.handleIframeLoaded);
  }

  unmount() {
    if (this.resizer) {
      this.resizer.close()
    }
    this.element.removeEventListener('load', this.handleIframeLoaded);
  }
}
