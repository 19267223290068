import Gallery from './Gallery';

document.addEventListener('DOMContentLoaded', () => {
  const galleryElement = document.querySelectorAll('.c-gallery');
  if (!galleryElement.length) {
    return;
  }

  galleryElement.forEach((element) => {
    const gallery = new Gallery(element);
    gallery.mount(element);
  })
});
