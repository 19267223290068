import Campus from './Campus';

document.addEventListener('DOMContentLoaded', () => {
  const campusElement = document.querySelectorAll('.o-blocks__item--campus');
  if (!campusElement.length) {
    return;
  }

  campusElement.forEach((element) => {
    const campus = new Campus(element);
    campus.mount(element);
  })
});
