import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

export default class Campus {
  constructor(element) {
    this.element = element;
    this.handleResize = this.handleResize.bind(this);
  }

  mount() {
    const isReduced =
  window.matchMedia(`(prefers-reduced-motion: reduce)`) === true ||
  window.matchMedia(`(prefers-reduced-motion: reduce)`).matches === true;

    if (!!isReduced) {
    } else {
      gsap.registerPlugin(ScrollTrigger)
      this.addAnimation()
    }
  }

  handleResize() {
    this.addAnimation();
  }

  addAnimation(){
    let rowHeight = document.querySelector(".campus__gallery__row-2").offsetHeight;
    let rowWidth = document.querySelector(".campus__gallery__row-2").offsetWidth;
    let wpHeight = window.innerHeight;
    let wpWidth = window.innerWidth;
    ScrollTrigger.matchMedia({
      // desktop
      "(min-width: 1200px) and (prefers-reduced-motion: no-preference)": function () {
        gsap.set(".campus__gallery__row-1", {
          y: (rowHeight- wpHeight)*(-1),
          x: 0
        });


        gsap.from(".campus__gallery__row-1", {
          scrollTrigger: {
            trigger: ".o-blocks__item--campus",
            start: "top bottom",
            end: "bottom top",
            scrub: 0,
          },
          y: 0 ,
          ease: "none",
          duration: 1
        });

        gsap.set(".campus__gallery__row-2", {
          y: 0,
          x: 0
        });

        gsap.from(".campus__gallery__row-2", {
          scrollTrigger: {
            trigger: ".o-blocks__item--campus",
            start: "top bottom",
            end: "bottom top",
            scrub: 0
          },
          y: (rowHeight- wpHeight)*(-1),
          ease: "none",
          duration: 1
        });

        gsap.set(".campus__gallery", {
          x: 0,
          y: 0
        });
      },

      "(max-width: 1200px)": function () {
        gsap.set(".campus__gallery", {
          x: -200,
          y: 0
        });

        gsap.set(".campus__gallery__row-2", {
          x: 0,
          y: 0
        });

        gsap.set(".campus__gallery__row-1", {
          x: 0,
          y: 0
        });

        gsap.from(".campus__gallery", {
          scrollTrigger: {
            trigger: ".o-blocks__item--campus",
            start: "top bottom",
            end: "bottom top",
            scrub: 0,
          },
          x: 0,
          ease: "none",
          duration: 1
        });
      },
      all: function () {}
    });
  }
}
