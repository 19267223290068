import 'mdn-polyfills/NodeList.prototype.forEach';
import Viewport from './Viewport';

document.addEventListener('DOMContentLoaded', () => {
  const isReduced =
  window.matchMedia(`(prefers-reduced-motion: reduce)`) === true ||
  window.matchMedia(`(prefers-reduced-motion: reduce)`).matches === true;

    if (!!isReduced) {
    } else {
      // const observedElements = document.querySelectorAll('.o-blocks__item');
      const observedElements = document.querySelectorAll('.is-observed');
      if (!observedElements.length) {
        return;
      }
      var halfHauter = window.innerHeight * (-0.1);

      if(!!window.IntersectionObserver){
        let observer = new IntersectionObserver((entries, observer) => {
          entries.forEach(entry => {
            if(entry.isIntersecting){
              const observedElement = new Viewport(entry.target);
              observedElement.mount();
            }
          });
        }, {rootMargin: "0px 0px "+ halfHauter+"px 0px"});
        document.querySelectorAll('.is-observed').forEach(observedElement => {
          observedElement.classList.add("is-hidden")
          observer.observe(observedElement) });
      }
    }
});
