
import GLightbox from 'glightbox';

export default class Gallery {
  constructor(element) {
    this.element = element;
  }

  mount() {
    GLightbox({
      'selector': '.glightbox'
    });
  }
}
