import 'mdn-polyfills/NodeList.prototype.forEach';
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

export default class Slider {
  constructor(element) {
    this.element = element;
    this.next = document.querySelector(".swiper-button-next .c-button");
    this.prev = document.querySelector(".swiper-button-prev .c-button");

    this.swiperElement = this.element;

    this.settings = {
      modules: [Navigation, Pagination],
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      pagination: {
        el: '.swiper-pagination',
        type: "fraction",
      },
      on: {
        init: (swiper) => {
          this.updateNavigation(swiper);
        },
        slideChange: (swiper) => {
          this.updateNavigation(swiper);
        },
        slideChangeTransitionEnd:(swiper)=> {
          this.updateTabIndex(swiper);
        }
      }

    };

    this.swiper;
  }

  updateTabIndex(){
    let slides = document.querySelectorAll(".swiper-slide");

    for (let i = 0; i < slides.length; i++) {
      const element = slides[i];
      if (element.classList.contains("swiper-slide-active")) {
        element.querySelector("a").setAttribute('tabIndex', '0');
      }else {
        element.querySelector("a").setAttribute('tabIndex', '-1');
      }

    }
  }

  updateNavigation(swiper) {
    this.prev.removeAttribute('disabled');
    this.next.removeAttribute('disabled');

    if (swiper.isBeginning) {
      this.prev.setAttribute('disabled', '');
    }
    if (swiper.isEnd) {
      this.next.setAttribute('disabled', '');
    }
  }



  mount() {
    this.settings = {
      ...this.settings,
    }

    this.swiper = new Swiper(this.swiperElement, this.settings);
    this.updateTabIndex()


    this.swiper.on('reachBeginning', () => {
      this.prev.setAttribute('disabled', '');
    });

    this.swiper.on('reachEnd', () => {
      this.next.setAttribute('disabled', '');
    });
  }

  unmount() {
    this.destroy(this.swiper, true);
  }
}
